/* Footer.css */

.footer {
    background-color: #e5e5e5;
    padding: 30px 0;
    color: #292929;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .footer-column {
    flex: 1;
    margin: 0 20px;
    text-align: left;
  }
  
  .footer-column h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .footer-column p {
    margin-bottom: 8px;
  }
  
  /* Responsive Styling */
  @media screen and (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
      margin: 4%;
    }
  
    .footer-column {
      margin: 20px 0;
    }
  }
  