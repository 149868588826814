/* FoundersPage.css */

.founders-page {
  padding: 20px;
  text-align: center;
}

.founders-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Founder.css */

.founder {
    width: 17vw;
    height: 65vh;
    padding: 2%;
    border-radius: 10px;
    background-color: whitesmoke;
    box-shadow: 4px 5px 10px whitesmoke;
}

.founder-photo {
    width: 90%;
    height: 50%;
    margin: 5%;
    margin-bottom: 1%;
    text-align: center;
}

.founder-photo img{
    width: 100%;
}

.founder h3 {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .founder {
    width: 70vw;
    height: 60vh;
    padding: 4%;
  }

  .founder-photo {
    height: 50%;
      margin-bottom: 0%;
  }
}
