/* styles.css */
.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .product-image {
    width: 300px;
    height: 300px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .product-details {
    text-align: center;
  }
  
  .product-title {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
  
  .product-category {
    font-style: italic;
    color: #666;
    margin-bottom: 5px;
  }
  
  .product-description {
    color: #333;
  }
  