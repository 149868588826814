
.App {
  text-align: center;
}

.header {
  overflow: hidden;
  height: 70px;
}

.page-content {
  
}

.footer {
  background-color: #333;
  color: #fff;
}

.ImagePromotionBox{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
  padding: 2%;
  overflow: hidden;
}

.ImagePromotionBox .MoreDetails{
 width: 100%; 
}

.ImagePromotionBox img{
  width: 24%;
  margin: 0.5%;
  border-radius: 10px;
}

.PerformerJs{
  margin: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
  margin-top: 4%;
  padding: 2%;
  overflow: hidden;
}

.PerformerJs .Title{
  font-size: 20pt;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.PerformerJs .Category{
  width: 38%;
  margin: 3%;
  padding: 2%;
  height: 15%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;
}

.PerformerJs .Category .Img{
  width: 10vw;
  height: 10vw;
  border-radius: 10px;
  overflow: hidden;
}

.PerformerJs .Category .Text{

}

.Bruh{
  width: 100%;
  overflow: hidden;
}

.Bruh img{
  width: 100%;
}

/* Media Query for Responsive Design */
@media only screen and (max-width: 768px) {

  .PerformerJs{
    margin-top: 15%;
  }

  .PerformerJs .Title{
    margin-bottom: 10%;
  }

  .PerformerJs .Category{
    width: 94%;
    margin: 3%;
    padding: 3%;
    height: 40vh;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    border-radius: 10px;
    cursor: pointer;
  }

  .PerformerJs .Category .Img{
    width: 50%;
    height: 50%;
  }

  .ProfileExport{
    margin: 2%;
  }
  
}