.navbar {
    background-color: #ffffffdd;
    width: 100%;
    height: 100%;
  }
  

  .navbar .Menu{
    position: absolute;
    width: 70%;
    height: 55px;
    right: 1%;
    top: 1%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    flex-direction: row;
  }


  .Menu .MenuItem{
    margin-left: 2%;
    margin-right: 2%;
    cursor: pointer;

    min-width: 60px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    flex-direction: column;
  }

  .MenuItem label{
    margin-left: 0.2%;
    font-size: 8pt;
  }

  .MenuItem:hover label{
    font-weight: bold;
    cursor: pointer;
  }

  .ToggleButton{
    width: 0;
    height: 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    flex-direction: row;
  }

    .Logo{
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      align-items: center;
      align-content: center;
      overflow: hidden;
      flex-direction: row;
    height: 100%;
    width: 90%;
    padding-left:4%;
    margin: 2%;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 15pt;
    text-align: left;
  }

  .ExitTouchArea{
    width: 0;
    height: 0;
    overflow: hidden;

  }

  .Logo{

  }

  .ImageSliderBox{

  }

  @media screen and (max-width: 600px) {

  .ToggleButton{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 1%;
    right: 2%;
  }
    
  .navbar .Menu{
    position: absolute;
    width: auto;
    top: 10vh;
    height: 90vh;
    right: 2%;
    left: 2%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #e9e9e9;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    flex-direction: column;
}


.ExitTouchArea{
  width: 90%;
  margin: 1%;
  margin-top: 0;
  height: 5vh;
  background-color: whitesmoke;
  opacity: 0.5;
  border-radius: 5px;
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    flex-direction: column;
}

.ExitTouchArea label{
  color: grey;
  font-weight: 200;
  font-size: 10pt;
  letter-spacing: 4px;
  text-align: center;
}

.Menu .MenuItem{
  margin-left: 2%;
  margin-right: 2%;
  cursor: pointer;

  min-width: 100px;
  min-height: 100px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: hidden;
  flex-direction: column;
}

.MenuItem label{
  margin-left: 0.2%;
  font-size: 12pt;
}

.MenuItem:hover label{
  font-weight: bold;
  cursor: pointer;
}

  .Show{
    animation-name: slideInFromBottom;
    animation-duration: 1s; /* Duration of the animation */
    visibility: visible;
    transform: translateY(0%);
  }

  .Hide{
    animation-name: slideOutToBottom;
    animation-duration: 1s; /* Duration of the animation */
    transform: translateY(100%);
    opacity: 0;
  }


  @keyframes slideInFromBottom {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideOutToBottom {
    from {
        transform: translateY(0%);
        opacity: 1;
      }
      to {
        transform: translateY(100%);
        opacity: 0;
      }
  }

  .FadeOutImage{
    animation-name: FadeOut;
    animation-duration: 1s; /* Duration of the animation */
  }

  @keyframes FadeOut {
    from {
      opacity: 1;
      }
      to {
        opacity: 0.1;
      }
  }

  .FadeInImage{
    animation-name: FadeIn;
    animation-duration: 2s; /* Duration of the animation */
  }

  @keyframes FadeIn {
    from {
      opacity: 0.1;
      }
      to {
        opacity: 1;
      }
  }

  .ImageSliderBox{
    margin: 10%;
    width: 80%;
    height: 50vh;
    border-radius: 10px;
    overflow: hidden;
  }  

  
  .MoreDetails{
    margin: 5%;
    width: 90%;
  }

  }